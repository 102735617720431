import './App.css';
import Header from './components/Header';


function App() {
  return (
    <>
    <Header/>
 
    
    </>
  );
}

export default App;
