import React from "react";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import cg from "../img/cg.jpg";
import eye from "../img/eye.jpg";
import nasaldrop from "../img/nasaldrop.jpg";

const products = [
  {
    img: cg,
    title: "DESI COW GHEE ",
    volume: "1 Ltr.",
    price: "RS.1600.00",
  },
  {
    img: cg,
    title: "DESI COW GHEE",
    volume: "500 Ml.",
    price: "RS.800.00",
  },
  {
    img: eye,
    title: "NETRA DHARA Eyedrops",
    volume: "15 Ml.",
    price: "RS.55.00",
  },
  {
    img: nasaldrop,
    title: "NASYA GHEE Nasal Drops",
    volume: "15 Ml.",
    price: "RS.100.00",
  },
];

const Productcard = () => {
  return (
    //     <div className="container mx-auto">
    //       <div className="hidden lg:flex flex-wrap justify-center">
    //         {products.map((product, index) => (
    //           <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-6">
    //             <Card className="h-full">
    //               <Card.Img src={product.img} alt={product.title} className="h-60 object-cover" />
    //               <Card.Body className='bg-gray-100'>
    //                 <Card.Title className="text-center">{product.title}</Card.Title>
    //                 <p className="text-center text-green-700">{product.volume}</p>
    //                 <p className="text-center text-green-700 font-bold">{product.price}</p>
    //                 <p className="text-center text-gray-500">+ Courier Charges</p>
    //               </Card.Body>
    //             </Card>
    //           </div>
    //         ))}
    //       </div>
    //       {/* <div className="lg:hidden">
    //         <Carousel showArrows={true} showThumbs={false} infiniteLoop={true}>
    //           {products.map((product, index) => (
    //             <div key={index} className="p-4">
    //               <Card className="h-full">
    //                 <Card.Img src={product.img} alt={product.title} className="h-64 object-cover" />
    //                 <Card.Body>
    //                   <Card.Title className="text-center">{product.title}</Card.Title>
    //                   <p className="text-center text-green-700">{product.volume}</p>
    //                   <p className="text-center text-green-700 font-bold">{product.price}</p>
    //                   <p className="text-center text-gray-500">+ Courier Charges</p>
    //                 </Card.Body>
    //               </Card>
    //             </div>
    //           ))}
    //         </Carousel>
    //       </div> */}
    //       <div className="lg:hidden">
    //   <Carousel
    //     showArrows={true}
    //     showThumbs={false}
    //     infiniteLoop={true}
    //     className="pointer-events-none h-60" // Added Tailwind classes
    //   >
    //     {products.map((product, index) => (
    //       <div key={index} className="p-4">
    //         <Card className="h-full">
    //           <Card.Img
    //             src={product.img}
    //             alt={product.title}
    //             className="h-64 object-cover"
    //           />
    //           <Card.Body>
    //             <Card.Title className="text-center">{product.title}</Card.Title>
    //             <p className="text-center text-green-700">{product.volume}</p>
    //             <p className="text-center text-green-700 font-bold">
    //               {product.price}
    //             </p>
    //             <p className="text-center text-gray-500">+ Courier Charges</p>
    //           </Card.Body>
    //         </Card>
    //       </div>
    //     ))}
    //   </Carousel>
    // </div>
    //     </div>
    <div className="container mx-auto">
      <div className="hidden lg:flex flex-wrap justify-center">
        {products.map((product, index) => (
          <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-6">
            <Card className="h-full">
              <Card.Img
                src={product.img}
                alt={product.title}
                className="h-60 object-cover"
              />
              <Card.Body className="bg-gray-100">
                <Card.Title className="text-center">{product.title}</Card.Title>
                <p className="text-center text-green-700">{product.volume}</p>
                <p className="text-center text-green-700 font-bold">
                  {product.price}
                </p>
                <p className="text-center text-gray-500">+ Courier Charges</p>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      <div className="lg:hidden">
        <Carousel
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          stopOnHover={false}
          swipeable={false}
          emulateTouch={false}
          className="pointer-events-none "
        >
          {products.map((product, index) => (
            <div key={index} className="p-4">
              <Card className="h-full">
                <Card.Img
                  src={product.img}
                  alt={product.title}
                  className="h-64 object-cover"
                />
                <Card.Body>
                  <Card.Title className="text-center">
                    {product.title}
                  </Card.Title>
                  <p className="text-center text-green-700">{product.volume}</p>
                  <p className="text-center text-green-700 font-bold">
                    {product.price}
                  </p>
                  <p className="text-center text-gray-500">+ Courier Charges</p>
                </Card.Body>
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Productcard;
