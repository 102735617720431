import React, { useRef, useState } from "react";
import "../App.css";
//import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import { Drawer } from "antd";
import Carousel from "react-bootstrap/Carousel";
import Product002 from "../img/Product002.jpg";
import Product003 from "../img/Product003.jpg";
import Product005 from "../img/Product005.jpg";
import Card from "react-bootstrap/Card";
import cowghee2 from "../img/cowghee2.jpg";
import cg from "../img/cg.jpg";
import eyedrops1 from "../img/eyedrops1.jpg";
import eye from "../img/eye.jpg";
import nasaldrops1 from "../img/nasaldrops1.jpg";
import nasaldrop from "../img/nasaldrop.jpg";
import ProductCard from "./ProductCard";
import logo from "../img/logo.png";
import org from "../img/org.png";
import org50 from "../img/org50.png";
import tick from "../img/tick.png";
import healthyfood from "../img/healthyfood.png";
import returnpolicy from "../img/returnpolicy.png";
import securepayment from "../img/securepayment.png";
import friendlysupport from "../img/friendlysupport.png";
import digestion from "../img/digestion.png";
import ldl from "../img/ldl.png";
import hearthealthy from "../img/hearthealthy.png";
import weightloss from "../img/weightloss.png";
import eyetonic from "../img/eyetonic.png";
import natural from "../img/natural.png";
import headache from "../img/headache.png";
import healthybreathing from "../img/healthybreathing.png";
import sleep from "../img/sleep.png";
import boost from "../img/boost.png";

import {
  FaArrowUp,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
} from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const Main = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [drawerContent, setDrawerContent] = useState({
    title: "",
    image: "",
    type: "",
  });
  const footerRef = useRef(null);
  const aboutRef = useRef(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleToggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const [open, setOpen] = useState(false);

  const showDrawer = (title, image, type) => {
    setDrawerContent({ title, image, type });
    setOpen(true);
  };

  const onClose = () => setOpen(false);

  const images = [
    {
      src: cg,
      title: "Sri Vanadhenu Desi Cow Ghee",
      contentImage: cg,
      ldl,
      type: "cowghee",
    },
    {
      src: eyedrops1,
      title: "Netra Dhara EyeDrops",
      contentImage: eye,
      type: "eyedrops",
    },
    {
      src: nasaldrops1,
      title: "Nasya Ghee Nasal Drops",
      contentImage: nasaldrop,
      type: "nasaldrop",
    },
  ];

  return (
    <>
      <header className="bg-lime-100 ">
        <nav className="container mx-auto max-h-20 flex items-center justify-evenly p-3 ">
          <div className="flex items-center ">
            <img src={logo} alt="" className="h-18 w-18" />
          </div>
          <ul className="hidden md:flex space-x-8 text-white font-medium">
            <li>
              <a
                href="/"
                className="text-green-700 no-underline hover:text-white"
              >
                HOME
              </a>
            </li>
            <li>
              <button
                onClick={() => handleScrollTo(aboutRef)}
                className="text-green-700 hover:text-white"
              >
                ABOUT US
              </button>
            </li>
            <li>
              <button
                onClick={() => handleScrollTo(footerRef)}
                className="text-green-700 hover:text-white"
              >
                CONTACT
              </button>
            </li>
          </ul>
          <div className="flex items-center space-x-4">
            {isSearchOpen && (
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search..."
                className="border rounded px-2 py-1"
              />
            )}
            <button onClick={handleToggleSearch} className="text-green-700">
              <i className="fas fa-search"></i>
            </button>
            {/* <button
              onClick={() => alert("Cart clicked!")}
              className="text-green-700"
            >
              <i className="fas fa-shopping-cart"></i>
            </button> */}
            <button
              onClick={handleToggleMenu}
              className="text-green-700 md:hidden"
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
        </nav>
        {isMenuOpen && (
          <div className="md:hidden">
            <ul className="flex flex-col space-y-4 p-4">
              <li>
                <a
                  href="/"
                  className="text-green-700 no-underline hover:text-white"
                >
                  HOME
                </a>
              </li>
              <li>
                <button
                  onClick={() => handleScrollTo(aboutRef)}
                  className="text-green-700 hover:text-white"
                >
                  ABOUT US
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleScrollTo(footerRef)}
                  className="text-green-700 hover:text-white"
                >
                  CONTACT
                </button>
              </li>
            </ul>
          </div>
        )}
      </header>
      {/* <Carousel>
        <Carousel.Item interval={1000}>
          <img className="d-block w-100" src={Product002} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img className="d-block w-100" src={Product003} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Product005} alt="Third slide" />
        </Carousel.Item>
      </Carousel> */}
      <Carousel interval={4000} controls={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={Product002} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Product003} alt="Second slide" />
          <Carousel.Caption>{/* Optional caption content */}</Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={Product005} alt="Third slide" />
          <Carousel.Caption>{/* Optional caption content */}</Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="text-center md:text-left my-8 px-4 md:px-8 lg:px-16">
        <h2 className="text-3xl md:text-4xl font-vladimir text-green-700">
          Featured{" "}
          <span className="text-3xl md:text-4xl font-vladimir text-blue-700">
            Collections
          </span>
        </h2>
        <h1 className="text-xl md:text-2xl font-bold text-black mt-4">
          Grown by nature & served by nature
        </h1>
        <p className="text-gray-600 mt-4 text-sm md:text-base lg:text-lg leading-relaxed">
          The farmer is the first link in the food chain. We connect them
          directly to you. Super fresh, really good quality, taste that stays
          with you. 100% natural, no pesticides or chemical fertilizers. A
          one-stop-shop for your organic food needs.
        </p>
      </div>

      {/* <div className="flex flex-wrap justify-center">
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-3">
          <Card className="h-full">
            <Card.Img
              src={cowghee}
              alt="Cow Ghee"
              className="h-96 object-cover"
            />
          </Card>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-3 ">
          <Card className="h-full">
            <Card.Img
              src={eyedrops}
              alt="Eye Drops"
              className="h-96 object-cover"
            />
          </Card>
        </div>
        <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 px-3 ">
          <Card className="h-full">
            <Card.Img
              src={nasaldrops}
              alt="Nasal Drops"
              className="h-96 object-cover"
            />
          </Card>
        </div> */}
      <div className="container mx-auto p-4 flex flex-wrap justify-evenly">
        {/* Cards only visible on larger screens */}
        <div className="row d-none d-md-flex justify-evenly">
          {images.map((image, index) => (
            <div className="col-md-4 mb-4 px-3" key={index}>
              <div className="card">
                <img
                  src={image.src}
                  className="card-img-top"
                  alt={image.title}
                />
                <div className="card-body">
                  <Button
                    type="primary"
                    onClick={() =>
                      showDrawer(image.title, image.contentImage, image.type)
                    }
                    className="ml-5 justify-center"
                  >
                    Product Info
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Drawer */}
        <Drawer
          title={drawerContent.title}
          placement={window.innerWidth < 768 ? "top" : "right"}
          onClose={onClose}
          open={open}
          width={window.innerWidth < 768 ? "100%" : "50%"}
          height={window.innerWidth < 768 ? "100%" : "auto"}
          style={{ overflow: "auto" }}
        >
          <img
            src={drawerContent.image}
            alt={drawerContent.title}
            className="w-64 mb-4 rounded-lg shadow-lg mx-auto"
          />
          {drawerContent.type === "cowghee" && (
            <div className="p-4">
              <div className="flex justify-between mb-4 flex-wrap">
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={ldl}
                    style={{ width: "50px", height: "50px" }}
                    alt="No bad Cholesterol"
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    No bad <br /> Cholesterol
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={hearthealthy}
                    alt="Heart-healthy fats"
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Heart-healthy <br /> fats
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={weightloss}
                    alt="Promotes weight-loss"
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Promotes <br /> weight-loss
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={digestion}
                    style={{ width: "50px", height: "50px" }}
                    alt="Aids digestion"
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Aids digestion
                  </p>
                </div>
              </div>
              <p className="underline font-bold text-black">Description:</p>
              <p className="text-gray-600 text-justify">
                Indulge in the purity and richness of our Desi Cow Ghee,
                hand-churned using the traditional bilona method. Made from milk
                sourced from desi cows, this ghee is not only a delicious
                addition to your cooking, but also a nutritional powerhouse.
                Packed with Vitamin A, calcium, Omega 6 & 9, iron, magnesium,
                butyric acid, and beta carotene, it helps maintain balance of
                kaph, vaat & pitta, nourishes your skin, aids in digestion,
                increases good cholesterol and reduces bad cholesterol. Enjoy
                the benefits of pure, natural ghee with every use, try Desi Cow
                Ghee today!
                <br />
                <br />
                Introducing Desi Cow Ghee - the traditional and nutritious
                cooking oil that is perfect for nourishing your body and soul.
                Made from hand-churned milk from desi cows, who graze in the
                forest, our Desi Cow Ghee is prepared using the bilona method
                for maximum flavor and quality.
                <br />
                <br />
                Our ghee is not just delicious, but it is also packed with
                essential nutrients like Vitamin A, calcium, omega 6 & 9, iron,
                magnesium, butyric acid, and beta carotene. These nutrients help
                in maintaining the balance of kaph, vaat & pitta, nourish the
                skin, aid in digestion, increase good cholesterol, and reduce
                bad cholesterol.
                <br />
                <br />
                Whether you are using it for cooking, baking, or just as a
                spread, Desi Cow Ghee is the perfect addition to your healthy
                lifestyle. Its rich flavor and creamy texture make it a great
                substitute for other oils and butter, while its nutritional
                benefits provide a bonus. So why wait? Start incorporating Desi
                Cow Ghee into your daily routine today and experience the many
                benefits of this traditional and nutritious cooking oil.
              </p>
            </div>
          )}
          {drawerContent.type === "eyedrops" && (
            <div className="p-4">
              <div className="flex justify-evenly mb-4 flex-wrap">
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={eyetonic}
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Powerful Herbal <br /> Eye Tonic Cholesterol
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={natural}
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Natural & Herbal <br /> Ingredients
                  </p>
                </div>
              </div>
              <p className="underline font-bold text-black">Description:</p>
              <p className="text-gray-600 text-justify">
                Gaunidhi Netra Dhara is a powerful herbal eye tonic that can
                help you achieve clear and healthy eyes. With just one drop in
                each eye, this natural formula can prevent a variety of
                eye-related diseases and discomforts. From red eyes and burning
                sensations to headaches and tension, our eye tonic can help
                relieve a range of eye-related discomforts.
                <br />
                Our eye tonic is made from a blend of potent herbs and
                botanicals, including cow urine, rose flower extract, Indian
                Berberry, anjanakeshi, beleric, myrobalan, behrad, and honey.
                These natural ingredients have been carefully selected for their
                powerful antioxidant and anti-inflammatory properties, which can
                help to reduce inflammation and support eye health.
                <br />
                Gaunidhi Netra Dhara is also effective in preventing early
                stages of cataract and improving eyesight. With regular use, you
                can experience the full range of benefits that this herbal eye
                tonic has to offer.
                <br />
                To use Gaunidhi Netra Dhara, simply add one drop to each eye
                daily. Our easy-to-use formula is gentle on the eyes and can be
                used by people of all ages. Whether you are experiencing
                discomfort due to extended screen time or seeking a natural way
                to maintain optimal eye health, our herbal eye tonic can help
                you achieve your goals.
                <br />
                Experience the benefits of Gaunidhi Netra Dhara and enjoy clear,
                healthy eyes for years to come.
              </p>
            </div>
          )}
          {drawerContent.type === "nasaldrop" && (
            <div className="p-4">
              <div className="flex justify-between mb-4 flex-wrap">
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={sleep}
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Improves the quality <br /> of sleep & reduces <br />
                    snoring
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={headache}
                    alt="Heart-healthy fats"
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Reduces headaches <br /> and migraines
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={healthybreathing}
                    alt="Promotes healthy"
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Promotes healthy <br /> breathing and <br /> clear sinuses
                  </p>
                </div>
                <div className="text-left mb-4 w-1/2 sm:w-1/4">
                  <img
                    src={boost}
                    style={{ width: "50px", height: "50px" }}
                    className="mx-auto"
                  />
                  <p className="text-gray-600 text-xs mt-1 text-center">
                    Boosts Immune <br /> system function
                  </p>
                </div>
              </div>
              <p className="underline font-bold text-black">Description:</p>
              <p className="text-gray-600 text-justify">
                Experience the ancient Ayurvedic practice of Nasya with our
                specially formulated Nasya Ghee. Nasya is the practice of
                lubricating the nasal passages with oil or ghee to promote
                healthy breathing and clear sinuses. Our Nasya Ghee is made from
                the purest ingredients and carefully crafted using traditional
                Ayurvedic methods.
                <br />
                Nasya Ghee is a natural and safe way to support healthy
                respiratory function, promote mental clarity, and relieve stress
                and tension. The herbs and botanicals infused in our ghee are
                specifically chosen for their beneficial properties, including
                turmeric, tulsi, and brahmi.
                <br />
                Simply apply a few drops of Nasya Ghee into each nostril daily
                to experience the many benefits of this ancient practice. Our
                Nasya Ghee is a convenient and easy-to-use way to support your
                overall health and well-being.
              </p>
            </div>
          )}
        </Drawer>

        {/* Carousel only visible on mobile */}
        <div className="d-block d-md-none mt-4">
          <Carousel>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={image.src}
                  alt={image.title}
                />
                <Carousel.Caption>
                  <Button
                    variant="success"
                    onClick={() =>
                      showDrawer(image.title, image.contentImage, image.type)
                    }
                  >
                    Product Info
                  </Button>
                </Carousel.Caption>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
      <ProductCard />
      <div className="text-center my-8">
        <h2 className="text-4xl  font-vladimir text-green-700">
          Organic Food By{" "}
          <span className="text-4xl  font-vladimir text-blue-700">
            Sri Vanadhenu
          </span>
        </h2>
        <h1 className="text-2xl font-bold text-black">
          Natural Taste From The Farm!
        </h1>
      </div>

      {/* <Container className="my-5 bg-slate-100">
        <div className="d-none d-md-block">
          <Row justify="center">
            <Col md={4} span={4} className="flex flex-row">
              <Image src={org} className=" p-3" />
              <div className="pt-3 pl-7 w-80">
                <span className="text-xl font-extrabold">100% Organic</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  We provide 100% Natural & Organic <br />
                  product without any added artificial <br />
                  chemicals
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex flex-row">
              <img src={healthyfood} alt="" className="p-3" />
              <div className="pt-3 pl-4 w-80">
                <span className="text-xl font-extrabold">Healthy Food</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  As our product are free from harmful <br />
                  chemicals, it's guaranteed to be <br /> healthy.
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex flex-row">
              <img src={tick} alt="" className="p-3" />
              <div className="pt-3 pl-4 w-80">
                <span className="text-xl font-extrabold">
                  Finest Ingredients
                </span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  Crafted with Care from the Finest <br />
                  Ingredients for Delicious & Healthy Goodness.
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className="pt-3">
            <Col md={4} span={4} className="flex flex-row">
              <img src={returnpolicy} alt="" className="p-3" />
              <div className="pt-4 pl-7">
                <span className="text-xl font-extrabold">Return Policy</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  We don't take back the Goods
                  <br />
                  once sold
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex flex-row">
              <img src={securepayment} alt="" className="p-3" />
              <div className="pt-4 pl-4">
                <span className="text-xl font-extrabold">Secure Payment</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  Shop with confidence knowing <br />
                  your payment information is <br />
                  always safe and secure.
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex flex-row">
              <img src={friendlysupport} alt="" className="p-3" />
              <div className="pt-4 pl-4">
                <span className="text-xl font-extrabold">Friendly Support</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  We are available on WhatsApp <br />
                  support from 9 am to 6 pm
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="d-md-none">
          <div className="flex flex-row">
            <Image src={org50} className="p-3" style={{ width: "40%" }} />
            <div className="pt-4">
              <span className="text-xl font-extrabold">100% Organic</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                We provide 100% Natural & <br />
                Organic product without any <br />
                added artificial chemicals
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <img
              src={healthyfood}
              alt=""
              className="p-3"
              style={{ width: "40%" }}
            />
            <div className="pt-4 pl-4">
              <span className="text-xl font-extrabold">Healthy Food</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                As our product are free from <br />
                harmful chemicals, it's <br />
                guaranteed to be healthy.
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <img src={tick} alt="" className="p-3" style={{ width: "40%" }} />
            <div className="pt-4 pl-4">
              <span className="text-xl font-extrabold">Finest Ingredients</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                Crafted with Care from the Finest <br />
                Ingredients for Delicious and <br />
                Healthy Goodness.
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <img
              src={returnpolicy}
              alt=""
              className="p-3"
              style={{ width: "40%" }}
            />
            <div className="pt-4 pl-7">
              <span className="text-xl font-extrabold">Return Policy</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                We don't take back the Goods
                <br />
                once sold
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <img
              src={securepayment}
              alt=""
              className="p-3"
              style={{ width: "40%" }}
            />
            <div className="pt-4 pl-4">
              <span className="text-xl font-extrabold">Secure Payment</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                Shop with confidence knowing <br />
                your payment information is <br />
                always safe and secure.
              </span>
            </div>
          </div>
          <div className="flex flex-row">
            <img
              src={friendlysupport}
              alt=""
              className="p-3"
              style={{ width: "40%" }}
            />
            <div className="pt-4 pl-4">
              <span className="text-xl font-extrabold">Friendly Support</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                We are available on WhatsApp <br />
                support from 9 am to 6 pm
              </span>
            </div>
          </div>
        </div>
      </Container> */}

      <Container className="my-5 bg-slate-100">
        <div className="hidden md:block">
          <Row justify="center">
            <Col md={4} span={4} className="flex items-center">
              <Image src={org} className="w-24 h-24 object-cover p-3" />
              <div className="pt-3 pl-4 w-64">
                <span className="text-xl font-extrabold">100% Organic</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  We provide 100% Natural & Organic <br />
                  product without any added artificial <br />
                  chemicals
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex items-center">
              <img
                src={healthyfood}
                alt=""
                className="w-24 h-24 object-cover p-3"
              />
              <div className="pt-3 pl-4 w-64">
                <span className="text-xl font-extrabold">Healthy Food</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  As our product are free from harmful <br />
                  chemicals, it's guaranteed to be <br />
                  healthy.
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex items-center">
              <img src={tick} alt="" className="w-24 h-24 object-cover p-3" />
              <div className="pt-3 pl-4 w-64">
                <span className="text-xl font-extrabold">
                  Finest Ingredients
                </span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  Crafted with Care from the Finest <br />
                  Ingredients for Delicious & Healthy Goodness.
                </span>
              </div>
            </Col>
          </Row>
          <Row justify="center" className="pt-3">
            <Col md={4} span={4} className="flex items-center">
              <img
                src={returnpolicy}
                alt=""
                className="w-24 h-24 object-cover p-3"
              />
              <div className="pt-4 pl-4 w-64">
                <span className="text-xl font-extrabold">Return Policy</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  We don't take back the Goods <br />
                  once sold
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex items-center">
              <img
                src={securepayment}
                alt=""
                className="w-24 h-24 object-cover p-3"
              />
              <div className="pt-4 pl-4 w-64">
                <span className="text-xl font-extrabold">Secure Payment</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  Shop with confidence knowing <br />
                  your payment information is <br />
                  always safe and secure.
                </span>
              </div>
            </Col>
            <Col md={4} span={4} className="flex items-center">
              <img
                src={friendlysupport}
                alt=""
                className="w-24 h-24 object-cover p-3"
              />
              <div className="pt-4 pl-4 w-64">
                <span className="text-xl font-extrabold">Friendly Support</span>
                <br />
                <span className="text-sm font-semibold text-gray-400">
                  We are available on WhatsApp <br />
                  support from 9 am to 6 pm
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="block md:hidden">
          <div className="flex flex-row items-center mb-4">
            <Image src={org50} className="w-24 h-24 object-cover p-3" />
            <div className="pt-4 w-64">
              <span className="text-xl font-extrabold">100% Organic</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                We provide 100% Natural & Organic <br />
                product without any added artificial <br />
                chemicals
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4">
            <img
              src={healthyfood}
              alt=""
              className="w-24 h-24 object-cover p-3"
            />
            <div className="pt-4 w-64">
              <span className="text-xl font-extrabold">Healthy Food</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                As our product are free from harmful <br />
                chemicals, it's guaranteed to be <br />
                healthy.
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4">
            <img src={tick} alt="" className="w-24 h-24 object-cover p-3" />
            <div className="pt-4 w-64">
              <span className="text-xl font-extrabold">Finest Ingredients</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                Crafted with Care from the Finest <br />
                Ingredients for Delicious and <br />
                Healthy Goodness.
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4">
            <img
              src={returnpolicy}
              alt=""
              className="w-24 h-24 object-cover p-3"
            />
            <div className="pt-4 w-64">
              <span className="text-xl font-extrabold">Return Policy</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                We have easy return policy
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4">
            <img
              src={securepayment}
              alt=""
              className="w-24 h-24 object-cover p-3"
            />
            <div className="pt-4 w-64">
              <span className="text-xl font-extrabold">Secure Payment</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                Shop with confidence knowing <br />
                your payment information is <br />
                always safe and secure.
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center mb-4">
            <img
              src={friendlysupport}
              alt=""
              className="w-24 h-24 object-cover p-3"
            />
            <div className="pt-4 w-64">
              <span className="text-xl font-extrabold">Friendly Support</span>
              <br />
              <span className="text-sm font-semibold text-gray-400">
                We are available on WhatsApp <br />
                support from 9 am to 6 pm
              </span>
            </div>
          </div>
        </div>
      </Container>

      <div className="my-8  h-6 bg-yellow-400"></div>
      {/* About Us Section */}
      <div ref={aboutRef} className="text-center my-8">
        <h2 className="text-4xl  font-vladimir text-green-700">
          What is Sri Vanadhenu
        </h2>
        <p className="text-gray-500 mt-4">
          Sri Vanadhenu Enterprises LLP started it's journey in the year 2019,
          in a humble manner by selling only one product i.e. Desi Cow Ghee and
          since then we <br /> are slowly growing and expanding. We have tied up
          with various manufactures & Vendors from various parts of Bharat and
          are dedicate to provide best
          <br />
          quality products.
        </p>
      </div>

      <div className="text-center my-8 bg-yellow-400 ">
        <h2 className="text-4xl  text-black font-bold pt-4 ">
          Why we are best?
        </h2>
        <p className="text-gray-500 mt-4 pb-4">
          Sri Vanadhenu is always dedicated to provide you with <br />
          natural & organic products which are easily available at <br />{" "}
          affordable rates without compromising the quality.
        </p>
      </div>
      <div ref={footerRef} className="bg-blue-500 my-8 text-white py-1 m-0">
        <Container>
          <Row>
            {/* <Col md={3} className="mb-4 md:mb-0">
              <h5 className="font-bold">Natural Organics</h5>
              <div className="h-1 w-10 bg-white my-2"></div>
              <p className="text-sm">
                Natural organics was founded for creating an effective and
                efficient bridge between Indian marginal farmers and buyers
                across the world seeking high-quality organic produce.
              </p>
            </Col> */}
            {/* <Col md={3} className="mb-4 md:mb-0">
              <h5 className="font-bold">Quick Links</h5>
              <div className="h-1 w-10 bg-white my-2"></div>
              <ul className="list-none p-0">
                <li className="text-sm mb-1">Search</li>
                <li className="text-sm mb-1">Privacy Policy</li>
                <li className="text-sm mb-1">Return & Cancellation</li>
                <li className="text-sm mb-1">Terms & Conditions</li>
              </ul>
            </Col> */}
            {/* <Col md={3} className="mb-4 md:mb-0">
              <h5 className="font-bold">Also Buy From</h5>
              <div className="h-1 w-10 bg-white my-2"></div>
              <ul className="list-none p-0">
                <li className="text-sm mb-1">Amazon</li>
              </ul>
            </Col> */}
            <Col md={3} className="mb-4 md:mb-0">
              <h5 className="font-bold">Contact Us</h5>
              <div className="h-1 w-10 bg-white my-2"></div>
              <p className="text-sm flex items-center">
                <FaMapMarkerAlt className="mr-2" /> Sri Vanadhenu Enterprises
                LLP
                <br /> Dadar (E), Mumbai -400 014 <br />
                Maharashtra
              </p>
              <div>
                <p className="text-sm flex items-center">
                  <FaPhoneAlt className="mr-2" /> +91 9169161153
                </p>
              </div>
              <div>
                <p className="text-sm flex items-center">
                  <FaEnvelope className="mr-2" /> vishaljoglekar@gmail.com
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <button
          onClick={scrollToTop}
          className="fixed bottom-6 right-8 bg-green-500 text-white p-3 rounded-full shadow-lg"
        >
          <FaArrowUp />
        </button>
        <a
          href="https://wa.me/9169161153?text=Hello...I want to place my order "
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-20 right-8 bg-green-500 text-white p-3 rounded-full shadow-lg"
        >
          <IoLogoWhatsapp />
        </a>
      </div>

      <div className="w-full h-16 text-center bg-green-500 flex items-center justify-center">
        <p className="text-white">
          Copyright &copy; 2024 Sri Vanadhenu. All rights reserved.
        </p>
      </div>
    </>
  );
};

export default Main;
